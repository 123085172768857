// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root > .app-container {
    height: 100%;
}

::-webkit-scrollbar {
    width: 0.4rem;
}
::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,kCAAkC;IAClC,mCAAmC;IACnC,kCAAkC;IAClC,8BAA8B;AAClC;;AAEA;IACI,SAAS;IACT,yBAAyB;AAC7B;;AAEA;;;;IAII,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,mBAAmB;IACnB,oBAAoB;AACxB","sourcesContent":[":root {\n    font-synthesis: none;\n    text-rendering: optimizeLegibility;\n    -webkit-font-smoothing: antialiased;\n    -moz-osx-font-smoothing: grayscale;\n    -webkit-text-size-adjust: 100%;\n}\n\nbody {\n    margin: 0;\n    overscroll-behavior: none;\n}\n\nhtml,\nbody,\n#root,\n#root > .app-container {\n    height: 100%;\n}\n\n::-webkit-scrollbar {\n    width: 0.4rem;\n}\n::-webkit-scrollbar-thumb {\n    border-radius: 12px;\n    visibility: 'hidden';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
